import { Button, Card, Spin } from 'antd';
import React from 'react';
import classes from './index.module.css';
import { connect } from 'react-redux';
const LoyaltyProgram = (props) => {
  const onClick = () => onSubmit({ id, data: { isActive: !isActive } })
  const { business , data: {_id: id, isActive}, isFetching, onSubmit } = props;
  const renderContent = () => (isActive === false ? (
    <>
      <span className={classes.header}>
        Your Loyalty program is currently not Active.
        {business.image == null ? ('Please provide a comprehensive Business Profile, including the company logo'):('')}
         
      </span>
      <div className="d-flex align-items-center mr-2 ml-2">
        <div className="d-flex align-items-center mr-4 ml-4">
          <span className="font-weight-bold mr-2">Status: </span>
          <span className={classes.red}>Inactive</span>
        </div>
        {business.image !== null ? (<Button onClick={onClick} type="primary">Activate</Button>) : (null)}
      </div>
    </>
  ) : (
    <>
      <span className={classes.header}>
        Your Loyalty program is currently Active
      </span>
      <div className="d-flex align-items-center mr-2 ml-2">
        <div className="d-flex align-items-center mr-4 ml-4">
          <span className="font-weight-bold mr-2">Status: </span>
          <span className={classes.green}>Active</span>
        </div>
        <Button
          onClick={onClick}
          type="danger"
        >
          Deactivate
        </Button>
      </div>
    </>
  ));

  return (
    <Card className={classes.card_main}>
      <Spin spinning={isFetching}>
        <div className={classes.card_main_div}>
          {renderContent()}
        </div>
      </Spin>
    </Card>

  );
};

const mapStateToProps = (store) => {
  return (
  {
    isFetching: store.posesStore.isFetching,
    poses: store.posesStore.poses,
    activePoses: store.posesStore.activePoses,
    subscription: store.businessStore.subscription,
    business: store.businessStore.business,
  })};
  const mapDispatchToProps = (dispatch) => ({
  });
export default connect(mapStateToProps,mapDispatchToProps)(LoyaltyProgram);
