import React from 'react';
import debounce from 'lodash/debounce';
import { Select, Spin } from 'antd';
import classes from './index.module.css';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions';

class DebounceSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fetching: false,
      options: [],
      // dropdownVisible:false
    };
    this.fetchRef = 0;
    this.debouncedFetcher = debounce(this.fetchOptions, this.props.debounceTimeout);
  }

  fetchOptions = async (value) => {
    this.fetchRef += 1;
    const fetchId = this.fetchRef;
    this.setState({ options: [], fetching: true });
    const newOptions = await this.props.fetchOptions(value);
    if (fetchId === this.fetchRef) {
      this.setState({ options: newOptions, fetching: false });
    }
  };

  handleChange = (value) => {
    const { uploadAvatar } = this.props;
    this.props.onChange(value);
    if (value) {
      // alert(value)
      // this.props.onDropdownVisibleChange(false);
      // const fromData = new FormData();
      //     fromData.append('file', value);
      let data = {
        fileurl: value
      }
          uploadAvatar(data);
      // this.setState({dropdownVisible:false})
    }
   
  };
  updateDropdown = () =>{
    // this.setState({dropdownVisible:false})
  }

  render() {
    const customDropdownRender = (menu) => (
      <div>
        {menu}
      </div>
    );
   
    const { fetching, options } = this.state;
    const { Option } = Select;
    return (
      <Select
        filterOption={false}
        showSearch={true}
        allowClear ={true}
        onSelect={this.handleChange}
        onSearch={this.debouncedFetcher}
        dropdownRender ={customDropdownRender}
        notFoundContent={fetching ? <Spin size="small" /> : null}
        onDropdownVisibleChange={this.updateDropdown}
        {...this.props}
       >
        {options?.map((option) => (
        <Option key={option?.value} value={option?.value}>
          <img src={option.imageUrl} alt={option.label} className={classes.dropdownImage} />
          {option.label}
        </Option>
      ))}
        </Select>
    );
  }
}
const mapStateToProps = (state) => ({
  isFetching: state.businessStore.isFetching,
  business: state.businessStore.business,
  subscription: state.businessStore.subscription,
});

const mapDispatchToProps = (dispatch) => ({
  update: (data) => dispatch(actions.updateSelf(data)),
  uploadAvatar: (data) => dispatch(actions.updateBusinessAvatar(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DebounceSelect);
