
import React, {
  useEffect,
  useRef,
  useState
} from "react";

const GooglePlace = (props) => {
    const placeInputRef = useRef(null);
    const [place, setPlace] = useState();
    const [value, setValue] = useState(props.address!==undefined?props.address:"");
    async function handleChange(e) {
      setValue(e.target.value);
      props.save({
        index: props.index,
        place: e.target.value,
        postalCode: 'NA',
        city:'NA',
        lat: 'NA',
        long: 'NA'
      });
    }

    // initialize the google place autocomplete
    useEffect(() => {
      initPlaceAPI();
    });
    const initPlaceAPI = () => {
      let autocomplete = new window.google.maps.places.Autocomplete(placeInputRef.current);
      new window.google.maps.event.addListener(autocomplete, "place_changed", function () {
        var city='NA';
        var postalCode='NA';
        let place = autocomplete.getPlace();
        if (Object.keys(place).length > 1) {
          setValue(place.formatted_address);
          for (const component of place.address_components) {
            const componentType = component.types[0];
        
            switch (componentType) {
          
              case "postal_code": {
                postalCode='';
                postalCode = `${component.long_name}${postalCode}`;
                break;
              }
              case "locality":
                city= component.long_name;
                break;
              default:break;
     
            }
          }
          props.save({
            index: props.index,
            place: place.formatted_address,
            postalCode: postalCode,
            city:city,
            lat: place.geometry.location.lat(),
            long: place.geometry.location.lng()
          });
          setPlace({
            address: place.formatted_address,
            lat: place.geometry.location.lat(),
            long: place.geometry.location.lng()
          });
        } else if (Object.keys(place).length > 0 && place.name !== '') {
          setValue(place.name);
          props.save({
            index: props.index,
            place: place.name,
            postalCode: 'NA',
            city:'NA',
            lat:'NA',
            long:'NA'
          });
        }
      });
    };

    return ( <>
      <input type = "text"
      className="form-control"
      style={{fontSize:"15px"}}
      value = {
        value
      }
      onChange = {
        handleChange
      }
      ref = {
        placeInputRef
      }
      /> {
        place && < div style = {
            {
              marginTop: 20,
              lineHeight: '25px'
            }
          } >
          </div>} </>
      );
    };

    export default GooglePlace;