import React, { useState, useEffect } from "react";
import GPlace from "./GooglePlace";

// API key of the google map
const GOOGLE_MAP_API_KEY = "AIzaSyA4sw3mVjttlTYk4aJc2mrRyXV5cIBpAV0";

// load google map script
const loadGoogleMapScript = callback => {
  if (
    typeof window.google === "object" &&
    typeof window.google.maps === "object"
  ) {
    callback();
  } else {
    const googleMapScript = document.createElement("script");
    googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}&libraries=places`;
    window.document.body.appendChild(googleMapScript);
    googleMapScript.addEventListener("load", callback);
  }
};

const GoogleMap = (props) => {
  const [loadMap, setLoadMap] = useState(false);

  useEffect(() => {
    loadGoogleMapScript(() => {
      setLoadMap(true);
    });
  }, []);

  return (
    <div className="App">
      {!loadMap ? <div>Loading...</div> : <GPlace {...props}/>}
    </div>
  );
};

export default GoogleMap;
